<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_703_8836)">
      <path
        d="M16.5 12.69V14.94C16.5008 15.1488 16.4581 15.3556 16.3744 15.547C16.2907 15.7383 16.168 15.9101 16.0141 16.0513C15.8601 16.1926 15.6784 16.3001 15.4806 16.367C15.2827 16.4339 15.073 16.4588 14.865 16.44C12.5571 16.1892 10.3402 15.4006 8.3925 14.1375C6.58037 12.9859 5.044 11.4496 3.8925 9.63745C2.62498 7.68085 1.83618 5.4532 1.59 3.13495C1.57125 2.92755 1.5959 2.71852 1.66237 2.52117C1.72884 2.32382 1.83567 2.14247 1.97607 1.98867C2.11647 1.83487 2.28735 1.71198 2.47784 1.62784C2.66833 1.5437 2.87425 1.50015 3.0825 1.49995H5.3325C5.69648 1.49637 6.04934 1.62526 6.32532 1.8626C6.60129 2.09994 6.78155 2.42954 6.8325 2.78995C6.92746 3.51 7.10358 4.217 7.3575 4.89745C7.4584 5.16589 7.48024 5.45764 7.42043 5.73811C7.36061 6.01858 7.22164 6.27603 7.02 6.47995L6.0675 7.43245C7.13516 9.31011 8.68984 10.8648 10.5675 11.9325L11.52 10.98C11.7239 10.7783 11.9814 10.6393 12.2618 10.5795C12.5423 10.5197 12.8341 10.5415 13.1025 10.6425C13.783 10.8964 14.4899 11.0725 15.21 11.1675C15.5743 11.2188 15.907 11.4024 16.1449 11.6831C16.3827 11.9638 16.5091 12.3221 16.5 12.69Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_703_8836">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<template>
  <nav class="relative shadow-lg md:py-4 z-10">
    <div
      class="
        md:hidden
        bg-deepBlue-500
        text-white text-sm text-center
        font-cabin font-bold
        py-3
      "
    >
      <a class="flex flex-col" href="tel:+18444350863"
        ><span class="text-xs font-normal">Talk to a Licensed Insurance Agent</span><span>Hours of operation: 8 am to 8 pm ET Everyday</span>+1 (844) 435-0863,
        TTY 711</a
      >
    </div>
    <div
      class="
        flex flex-row
        justify-center
        md:justify-between
        max-w-6xl
        mx-auto
        py-6
        md:py-0
        px-10
      "
    >
      <router-link to="/">
        <LogoColor />
      </router-link>
      <div class="flex flex-row items-center">
        <div class="hidden md:block mr-3">
          <p class="text-deepBlue-500 font-bold mr-3">Talk to a Licensed Insurance Agent</p>
          <p class="text-deepBlue-500 font-bold">Hours of operation: 8 am to 8 pm ET Everyday</p>
        </div>
        <PrimaryLink
          class="hidden md:flex flex-row items-center text-sm"
          :href="`tel:+18444350863`"
        >
          <PhoneIcon class="mr-2.5" />
          +1 (844) 435-0863, TTY 711
        </PrimaryLink>

      </div>
    </div>
  </nav>
</template>

<script>
import LogoColor from "@/components/LogoColor.vue";
import PrimaryLink from "@/components/PrimaryLink.vue";
import PhoneIcon from "@/components/PhoneIcon.vue";
export default {
  name: "Navbar",
  components: {
    LogoColor,
    PrimaryLink,
    PhoneIcon,
  },
};
</script>

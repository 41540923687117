<template>
  <div
    class="bg-white shadow-xl rounded-[35px] mt-12 py-[50px] md:py-[60px] xl:pt-[66px] xl:pb-[83px] px-[19px] md:px-[107px] xl:px-[125px] max-w-[792px] mx-auto"
  >
    <h4
      class="text-lg md:text-[26px] text-deepBlue-500 text-center font-bold px-4 md:px-0 mb-2.5 md:mb-5 xl:mb-[30px]"
    >
      Call now to speak with a licensed insurance agent and find out if you are eligible
      for a Medicare Advantage plan.
    </h4>
    <ArrowDownCircle class="mx-auto mb-5 md:mb-9 xl:mb-5" />
    <PrimaryLink
      class="flex justify-center text-sm mb-5"
      :href="`tel:+18444350863`"
      @click="call_initiated"
      >Tap to Call</PrimaryLink
    >
    <SecondaryLink
      class="flex flex-row justify-center items-center text-sm"
      :href="`tel:+18444350863`"
      @click="call_initiated"
    >
      <PhoneIconColor class="mr-2.5" />
      +1 (844) 435-0863, TTY 711
    </SecondaryLink>
  </div>
</template>

<script>
import ArrowDownCircle from "../ArrowDownCircle.vue";
import PrimaryLink from "../PrimaryLink.vue";
import SecondaryLink from "../SecondaryLink.vue";
import PhoneIconColor from "../PhoneIconColor.vue";
export default {
  name: "Quiz4",
  components: { ArrowDownCircle, PrimaryLink, SecondaryLink, PhoneIconColor },
  methods: {
    call_initiated: function () {
      if (window.gtag) window.gtag("event", "lead", { answer: "lead" });
    },
  },
};
</script>

<template>
  <section
    class="bg-bgGray py-[55px] md:py-[50px] xl:py-[100px] px-[38px] md:px-[92px] text-deepBlue-500"
  >
    <div class="max-w-6xl mx-auto xl:px-10">
      <div class="mb-6 md:mb-7">
        <Submark class="mx-auto xl:hidden" />
        <LogoColor class="hidden xl:block" />
      </div>
      <div class="mt-0.5 flex flex-col xl:flex-row items-center xl:justify-between">
        <div class="flex flex-col order-2 xl:order-1 text-center xl:text-left">
          <p class="text-sm font-cabin mb-2">
            Call to speak with a licensed insurance agent and find out if you are eligible
            for a Medicare Advantage plan.
          </p>
          <a class="text-sm font-cabin font-bold mb-2" :href="`tel:+18444350863`">
            +1 (844) 435-0863, TTY 711
          </a>
          <p class="text-sm font-cabin mb-5">Hours of operation: 8am to 8pm ET</p>
          <p class="text-sm font-cabin mb-5">
            &copy; 2023 Reserve Tech Inc. All rights reserved.
          </p>
        </div>
        <div
          class="flex flex-col xl:flex-row items-center space-y-6 md:space-y-[30px] xl:space-y-0 order-1 xl:order-2 mb-6 md:mb-[30px] xl:mb-0"
        >
          <a
            class="text-sm font-cabin text-blue-600 underline font-bold xl:mr-9 whitespace-nowrap"
            href="https://www.reservetechinc.com/privacy/"
          >
            Privacy Policy
          </a>
          <a
            class="text-sm font-cabin text-blue-600 underline font-bold xl:mr-9 whitespace-nowrap"
            href="https://www.reservetechinc.com/termsandconditions.php"
          >
            Terms & Conditions
          </a>
          <a
            class="text-sm font-cabin text-blue-600 underline font-bold xl:mr-9 whitespace-nowrap"
            href="/do-not-call"
          >
            Do Not Call Policy
          </a>
        </div>
      </div>
      <div>
        <p
          class="text-sm xl:text-sm text-center xl:text-left font-cabin px-8 md:px-3.5 xl:px-0 xl:mb-2 xl:mr-2"
        >
          Reserve Tech Inc. is a referral source that provides information and access to a
          helpline to match consumers with companies that may provide certain insurance
          coverage to them. Reserve Tech Inc. does not act as an insurance broker and does
          not make decisions about insurance coverage that may be available to you.
          Reserve Tech Inc. doesn't promise a specific outcome or the results you may
          achieve by calling the helpline. The helpline is free and you have no obligation
          to enroll. The services or programs that you pursue may have costs associated with them.
          Neither ReserveTech Inc., seniorcarebuddy.com, nor any of the supplemental insurance plans to
          which you may be connected are endorsed by the U.S. Government or the federal
          Medicare program. *Speak with a licensed insurance agent and find out if you are
          eligible for a Medicare Advantage plan. Our
          partners assist beneficiaries with applications and initial eligibility
          screening, but only the relevant state agency may determine eligibility.
          Provision of financial information to our partners is optional and does not
          affect enrollment eligibility. Our goal is to provide exceptional service. A
          licensed insurance agent may reach out to you to discuss your inquiry, ask for
          feedback, and/or see if you need any assistance with your products, services, or
          plans, at the phone number you provided regardless of your do-not-call list
          status. You may opt-out of further contact at any time by simply telling our
          customer service team that you would no longer like to be contacted. If in the
          event that our team is unable to reach you by phone, they may send you a text
          message letting you know that we called. Both our text messages and phone calls
          may be sent or connected utilizing automated software. Carrier charges may
          apply. You may opt-out of any future contact via text message by replying
          anytime with “STOP”. We do not offer every plan available in your area.
          Currently we represent 193 organizations which offer "4,624 products in your
          area. Please contact Welcome to Medicare | Medicare.gov, 1-800-MEDICARE, or your
          local State Health Insurance Program (SHIP) to get information on all of your
          options.<br />
          <br />
          Enrollment in a plan may be limited to certain times of the year unless you
          qualify for a Special Enrollment Period or you are in your Original Medicare
          Initial Election Period.
          <br />
          <br />
          Participating sales agencies represent Medicare Advantage [HMO, PPO and PFFS]
          organizations and stand-alone PDP Prescription Drug Plans that are contracted
          with Medicare. Enrollment depends on the plan's contract renewal.
          <br />
          <br />
          MULTI-PLAN_koh3sgdg2025_C
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import LogoColor from "./LogoColor.vue";
import Submark from "./Submark.vue";

export default {
  name: "Footer",
  components: {
    LogoColor,
    Submark,
  },
};
</script>

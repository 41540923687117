<template>
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.9999 12.6901V14.9401C17.0008 15.1489 16.958 15.3557 16.8743 15.5471C16.7907 15.7385 16.6679 15.9103 16.514 16.0515C16.3601 16.1927 16.1784 16.3002 15.9805 16.3671C15.7826 16.434 15.573 16.4589 15.3649 16.4401C13.0571 16.1893 10.8402 15.4007 8.89245 14.1376C7.08032 12.9861 5.54395 11.4497 4.39245 9.63757C3.12493 7.68098 2.33613 5.45332 2.08995 3.13507C2.07121 2.92767 2.09586 2.71864 2.16233 2.52129C2.22879 2.32394 2.33563 2.14259 2.47602 1.98879C2.61642 1.83499 2.7873 1.7121 2.97779 1.62796C3.16828 1.54382 3.37421 1.50027 3.58245 1.50007H5.83245C6.19643 1.49649 6.54929 1.62538 6.82527 1.86272C7.10125 2.10006 7.28151 2.42966 7.33245 2.79007C7.42742 3.51012 7.60354 4.21712 7.85745 4.89757C7.95836 5.16602 7.9802 5.45776 7.92038 5.73823C7.86056 6.01871 7.7216 6.27616 7.51995 6.48007L6.56745 7.43257C7.63512 9.31023 9.18979 10.8649 11.0675 11.9326L12.0199 10.9801C12.2239 10.7784 12.4813 10.6395 12.7618 10.5796C13.0423 10.5198 13.334 10.5417 13.6024 10.6426C14.2829 10.8965 14.9899 11.0726 15.71 11.1676C16.0743 11.219 16.407 11.4025 16.6448 11.6832C16.8827 11.9639 17.0091 12.3223 16.9999 12.6901Z"
      stroke="#1FA4A3"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<template>
  <section class="pb-[50px] md:py-12 xl:pt-20 xl:pb-24">
    <div class="xl:max-w-5xl px-6 md:px-10 xl:px-12 xl:mx-auto">
      <h3
        class="text-xl md:text-[26px] xl:text-[32px] leading-tight text-deepBlue-500 text-center font-bold font-ubuntu xl:mb-0.5 md:max-w-2xl xl:max-w-none md:px-12 xl:px-0 mx-auto"
      >
        Speak with a licensed insurance agent and find out if you are eligible for a Medicare Advantage plan.
      </h3>
      <p
        class="text-body xl:text-lg text-center font-ubuntu font-medium mt-5 xl:mt-7 mb-0.5"
      >
        Answer a few questions to find out if you are eligible
      </p>
      <Quiz1 v-show="step === 1" @yes="over65_yes" @no="over65_no" @skip="over65_skip" />
      <!-- <Quiz2
        v-show="step === 2"
        @yes="dental_vision_hearing_yes"
        @no="dental_vision_hearing_no"
        @skip="dental_vision_hearing_skip"
      /> -->
      <Quiz3 v-show="step === 2" />
    </div>
  </section>
</template>

<script>
import Quiz1 from "./Quiz/Quiz1.vue";
// import Quiz2 from "./Quiz/Quiz2.vue";
import Quiz3 from "./Quiz/Quiz3.vue";

export default {
  name: "Question",
  components: {
    Quiz1,
    // Quiz2,
    Quiz3,
  },
  data() {
    return {
      step: 1,
      over65: null,
      dental_vision_hearing: null,
    };
  },
  methods: {
    over65_yes: function () {
      if (window.gtag) window.gtag("event", "question_one", { answer: "question_1_yes" });
      this.step = 2;
      this.over65 = true;
    },
    over65_no: function () {
      if (window.gtag) window.gtag("event", "question_one", { answer: "question_1_no" });
      this.step = 2;
      this.over65 = false;
    },
    over65_skip: function () {
      if (window.gtag)
        window.gtag("event", "question_one", { answer: "question_1_skipped" });
      this.step = 2;
    },
    dental_vision_hearing_yes: function () {
      if (window.gtag) window.gtag("event", "question_two", { answer: "question_2_yes" });
      this.step = 3;
      this.dental_vision_hearing = true;
    },
    dental_vision_hearing_no: function () {
      if (window.gtag) window.gtag("event", "question_two", { answer: "question_2_no" });
      this.step = 3;
      this.dental_vision_hearing = false;
    },
    dental_vision_hearing_skip: function () {
      if (window.gtag)
        window.gtag("event", "question_two", { answer: "question_2_skipped" });
      this.step = 3;
    },
  },
};
</script>

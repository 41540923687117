<template>
  <a
    class="
      bg-white
      rounded-lg
      text-customTurquoise-600
      border-2 border-customTurquoise-600
      px-5
      py-4
      font-cabin font-bold
    "
  >
    <slot />
  </a>
</template>

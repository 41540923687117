<template>
  <a
    class="
      bg-customTurquoise-600
      rounded-lg
      text-white
      px-5
      py-4
      font-cabin font-bold
    "
  >
    <slot />
  </a>
</template>

<template>
  <Navbar />
  <section class="pb-[50px] md:py-12 xl:pt-20 xl:pb-24">
    <div class="xl:max-w-5xl px-6 md:px-10 xl:px-12 xl:mx-auto">
      <h1 class="font-bold text-center">AcquireCrowd - Do Not Call Policy</h1>

      <div class="my-3">
        If you don't want to receive sales calls from AcquireCrowd, or our
        affiliates, you can ask us to place your telephone number on our "Do Not
        Call" list. In compliance with federal and state laws, we'll document
        your request immediately. Please allow up to 30 days for your telephone
        number to be removed from any sales programs that are currently
        underway.
      </div>

      <ul class="list-disc">
        <li>
          Your request can be in writing or by phone, and must include, at a
          minimum, your telephone number.
        </li>
        <li>
          If you have multiple telephone numbers, tell us all numbers that you
          want to be included.
        </li>
        <li>
          You'll remain on our "Do Not Call" list for five years, unless you ask
          to be removed.
        </li>
        <li>
          If your telephone number ever changes, you must give us your new
          information for your "do not call" status to remain in effect.
        </li>
      </ul>
      <div class="my-3">
        When we solicit prospective customers, we also honor "do not call"
        requests on behalf of consumers listed on the National Do Not Call
        Registry maintained by the Federal Trade Commission and various
        state-agency lists. Many state "Do Not Call" regulations permit
        companies to contact their own customers even though they are on these
        "Do Not Call" lists. Therefore, if you are a customer, you may be
        contacted by us even though you are on a state or the national "do not
        call" list. If you do not want to be contacted even though you are a
        customer, simply follow the steps above to be placed on the "Do Not
        Call" list and your request will be honored. All employees that engage
        in outbound telephone solicitation are trained in this policy and are
        made aware of these procedures before they are allowed to place calls to
        consumers.
      </div>
    </div>
  </section>
  <Footer />
</template>

<script>
import Navbar from "../components/Navbar.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "HomeView",
  components: {
    Navbar,
    Footer,
  },
};
</script>

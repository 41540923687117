<template>
  <section class="relative flex flex-row justify-between">
    <div class="relative w-1/2 h-[275px] md:h-auto md:py-[116px] xl:py-[195px]">
      <div class="hidden md:block max-w-lg md:ml-10 xl:ml-auto mr-5">
        <h1
          class="text-deepBlue-500 md:text-[32px] xl:text-[56px] leading-tight font-bold font-ubuntu pr-5 xl:pr-2"
        >
          You may be eligible to enroll in a Medicare Advantage plan that may include additional plan benefits.
        </h1>
      </div>
    </div>
    <!-- <div class="relative w-[735px]">
      <img src="../assets/hero-right.png" alt="" class="absolute right-0" />
    </div> -->
  </section>
</template>

<template>
  <svg
    width="195"
    height="51"
    viewBox="0 0 195 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_802_758)">
      <path
        d="M33.8272 8.81969C33.8272 3.94934 29.8779 0 25.0025 0C20.1322 0 16.1829 3.94934 16.1829 8.81969V16.1778H33.8272V8.81969Z"
        fill="#A2A7DA"
      />
      <path
        d="M33.8272 33.8271H16.1829V50.0099H33.8272V33.8271Z"
        fill="#27CDCC"
      />
      <path
        d="M50.0099 16.1827H33.8271V33.8271H50.0099V16.1827Z"
        fill="#7DE1E0"
      />
      <path
        d="M8.81969 16.1827C3.94934 16.1827 0 20.1321 0 25.0024C0 29.8728 3.94934 33.8221 8.81969 33.8221H16.1778V16.1827H8.81969Z"
        fill="#7A81CA"
      />
      <path
        d="M33.8272 16.1827H16.1829V33.8271H33.8272V16.1827Z"
        fill="#555A8D"
      />
      <path
        d="M55.4609 31.87L56.7974 29.0419C58.1589 30.038 59.7857 30.904 62.3785 30.904C63.7951 30.904 65.522 30.2483 65.522 28.9118C65.522 27.5753 64.0003 27.26 62.5087 26.8946C59.3402 26.1588 56.0666 25.0876 56.0666 21.5487C56.0666 18.2751 59.13 16.6483 62.3785 16.6483C64.9464 16.6483 66.8835 17.3291 68.5854 18.4553L67.2489 21.2834C65.8874 20.3924 64.1054 19.9219 62.3785 19.9219C61.4876 19.9219 59.8107 20.2623 59.8107 21.5437C59.8107 22.6449 61.0421 23.0353 62.7189 23.4308C65.7322 24.1365 69.3462 25.2628 69.3462 28.9068C69.3462 32.5508 65.7823 34.1726 62.3785 34.1726C58.3191 34.1726 56.407 32.6259 55.4609 31.865V31.87Z"
        fill="#180C3C"
      />
      <path
        d="M71.4385 27.3351C71.4385 23.5609 74.3717 20.5226 78.2209 20.5226C81.7848 20.5226 84.5879 23.5609 84.5879 27.28C84.5879 27.9107 84.5078 28.5364 84.5078 28.5364H74.9974C75.4178 30.3434 76.9095 31.2093 78.6364 31.2093C79.8928 31.2093 81.2042 30.8439 82.1753 30.1882L83.4867 32.701C82.0201 33.672 80.3182 34.1425 78.6164 34.1425C74.7922 34.1425 71.4385 31.6548 71.4385 27.3301V27.3351ZM81.2893 26.2088C81.029 24.557 79.7176 23.4608 78.1208 23.4608C76.5241 23.4608 75.2927 24.562 74.9523 26.2088H81.2893Z"
        fill="#180C3C"
      />
      <path
        d="M86.8955 20.7879H90.3293V22.5448C91.3754 21.1032 92.8721 20.5276 94.4688 20.5276C97.9276 20.5276 99.1339 22.755 99.1339 26.344V33.8873H95.6251V26.7895C95.6251 24.8774 95.1295 23.8562 93.3476 23.8562C91.5656 23.8562 90.4144 25.2728 90.4144 27.26V33.8873H86.9055V20.7879H86.8955Z"
        fill="#180C3C"
      />
      <path
        d="M101.276 17.1188C101.276 15.9675 102.217 15.0215 103.344 15.0215C104.47 15.0215 105.441 15.9625 105.441 17.1188C105.441 18.2751 104.5 19.1861 103.344 19.1861C102.187 19.1861 101.276 18.245 101.276 17.1188ZM101.617 20.7878H105.126V33.8872H101.617V20.7878Z"
        fill="#180C3C"
      />
      <path
        d="M107.408 27.3351C107.408 23.5359 110.496 20.5226 114.401 20.5226C118.305 20.5226 121.418 23.5359 121.418 27.3351C121.418 31.1342 118.33 34.1475 114.401 34.1475C110.471 34.1475 107.408 31.1342 107.408 27.3351ZM117.834 27.3351C117.834 25.2928 116.368 23.7712 114.401 23.7712C112.433 23.7712 110.967 25.2928 110.967 27.3351C110.967 29.3773 112.433 30.899 114.401 30.899C116.368 30.899 117.834 29.4073 117.834 27.3351Z"
        fill="#180C3C"
      />
      <path
        d="M123.701 20.7879H127.16V23.3006C127.525 22.2245 128.862 20.5226 131.745 20.5226V24.3468C128.706 24.3468 127.215 25.7333 127.215 28.8517V33.8823H123.706V20.7829L123.701 20.7879Z"
        fill="#180C3C"
      />
      <path
        d="M139.158 25.3729C139.158 20.5526 142.877 16.6483 148.118 16.6483C150.45 16.6483 152.548 17.4342 154.064 18.7957L151.812 21.2584C150.66 20.2623 149.479 19.977 148.328 19.977C145.24 19.977 142.982 22.3846 142.982 25.3729C142.982 28.3612 145.184 30.8489 148.193 30.8489C149.764 30.8489 151.206 30.2483 152.252 29.092L154.53 31.6598C152.908 33.2315 150.706 34.1726 148.113 34.1726C142.872 34.1726 139.153 30.2683 139.153 25.3729H139.158Z"
        fill="#180C3C"
      />
      <path
        d="M155.846 27.3351C155.846 23.5609 158.154 20.5226 161.768 20.5226C163.289 20.5226 164.911 21.1232 165.852 22.3846V20.7879H169.286V33.8873H165.852V32.3155C164.936 33.5719 163.284 34.1475 161.768 34.1475C158.154 34.1475 155.846 31.1092 155.846 27.3351ZM165.772 27.3351C165.772 25.2928 164.461 23.7712 162.654 23.7712C160.847 23.7712 159.405 25.2928 159.405 27.3351C159.405 29.3773 160.767 30.899 162.654 30.899C164.541 30.899 165.772 29.4324 165.772 27.3351Z"
        fill="#180C3C"
      />
      <path
        d="M171.954 20.7879H175.413V23.3006C175.778 22.2245 177.115 20.5226 179.998 20.5226V24.3468C176.959 24.3468 175.468 25.7333 175.468 28.8517V33.8823H171.959V20.7829L171.954 20.7879Z"
        fill="#180C3C"
      />
      <path
        d="M181.414 27.3351C181.414 23.5609 184.348 20.5226 188.197 20.5226C191.761 20.5226 194.564 23.5609 194.564 27.28C194.564 27.9107 194.484 28.5364 194.484 28.5364H184.973C185.394 30.3434 186.885 31.2093 188.612 31.2093C189.869 31.2093 191.18 30.8439 192.151 30.1882L193.463 32.701C191.996 33.672 190.294 34.1425 188.592 34.1425C184.768 34.1425 181.414 31.6548 181.414 27.3301V27.3351ZM191.265 26.2088C191.005 24.557 189.693 23.4608 188.097 23.4608C186.5 23.4608 185.269 24.562 184.928 26.2088H191.265Z"
        fill="#180C3C"
      />
      <path
        d="M55.4609 40.6647H59.7106C61.2573 40.6647 62.3585 41.3955 62.3585 42.8821C62.3585 43.653 61.903 44.3437 61.3124 44.599C62.3585 44.8142 63.2745 45.7853 63.2745 47.0867C63.2745 48.9037 61.888 49.9349 59.7707 49.9349H55.4659V40.6647H55.4609ZM59.8257 44.2536C60.6566 44.2536 61.1572 43.708 61.1572 42.9672C61.1572 42.1513 60.6116 41.6658 59.6405 41.6658H56.6072V44.2536H59.8257ZM59.6956 48.9338C61.1422 48.9338 62.0682 48.288 62.0682 47.1017C62.0682 45.9855 61.2223 45.2397 59.9809 45.2397H56.6072V48.9288H59.6956V48.9338Z"
        fill="#180C3C"
      />
      <path
        d="M70.6577 46.2458V40.6647H71.804V46.2008C71.804 47.9176 72.75 49.0188 74.5069 49.0188C76.2639 49.0188 77.2099 47.9176 77.2099 46.2008V40.6647H78.3562V46.2458C78.3562 48.5633 76.9096 50.08 74.5069 50.08C72.1043 50.08 70.6577 48.5633 70.6577 46.2458Z"
        fill="#180C3C"
      />
      <path
        d="M86.1345 40.6647H89.6534C92.6267 40.6647 94.5037 42.767 94.5037 45.2998C94.5037 47.8325 92.6317 49.9349 89.6534 49.9349H86.1345V40.6647ZM89.5833 48.9338C91.8158 48.9338 93.3174 47.3871 93.3174 45.2998C93.3174 43.2125 91.8158 41.6658 89.5833 41.6658H87.2808V48.9338H89.5833Z"
        fill="#180C3C"
      />
      <path
        d="M102.017 40.6647H105.536C108.509 40.6647 110.386 42.767 110.386 45.2998C110.386 47.8325 108.514 49.9349 105.536 49.9349H102.017V40.6647ZM105.466 48.9338C107.698 48.9338 109.2 47.3871 109.2 45.2998C109.2 43.2125 107.698 41.6658 105.466 41.6658H103.163V48.9338H105.466Z"
        fill="#180C3C"
      />
      <path
        d="M120.032 45.545L116.298 40.6647H117.614L120.077 43.7981C120.377 44.1986 120.608 44.569 120.608 44.569C120.608 44.569 120.878 44.1835 121.168 43.7981L123.586 40.6647H124.917L121.183 45.53V49.9349H120.037V45.545H120.032Z"
        fill="#180C3C"
      />
    </g>
    <defs>
      <clipPath id="clip0_802_758">
        <rect width="194.564" height="50.08" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

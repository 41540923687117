<template>
  <section
    class="
      px-6
      md:px-0
      pt-[50px]
      md:pt-[54px]
      xl:pt-[120px]
      pb-[69px]
      md:pb-[50px]
      xl:pb-[100px]
    "
  >
    <div>
      <h3
        class="
          text-xl
          md:text-[26px]
          xl:text-[32px]
          text-deepBlue-500 text-center
          font-ubuntu font-bold
          mb-6
          md:mb-[44px]
          xl:mb-[50px]
        "
      >
        Looking for help with Medicare Advantage plan coverage?
      </h3>
      <PrimaryLink
        class="
          flex flex-row
          justify-center
          items-center
          text-sm
          md:max-w-[237px]
          mx-auto
        "
        :href="`tel:+18444350863`"
      >
        <PhoneIcon class="mr-2.5" />
        +1 (844) 435-0863, TTY 711
      </PrimaryLink>
    </div>
  </section>
</template>

<script>
import PrimaryLink from "./PrimaryLink.vue";
import PhoneIcon from "./PhoneIcon.vue";

export default {
  name: "CallToAction",
  components: {
    PrimaryLink,
    PhoneIcon,
  },
};
</script>

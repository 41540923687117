<template>
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30 2.5C14.8125 2.5 2.5 14.8125 2.5 30C2.5 45.1875 14.8125 57.5 30 57.5C45.1875 57.5 57.5 45.1875 57.5 30C57.5 14.8125 45.1875 2.5 30 2.5ZM39.2675 24.2675C39.7229 23.796 39.9749 23.1645 39.9692 22.509C39.9635 21.8535 39.7006 21.2265 39.237 20.763C38.7735 20.2994 38.1465 20.0365 37.491 20.0308C36.8355 20.0251 36.204 20.2771 35.7325 20.7325L30 26.465L24.2675 20.7325C24.0369 20.4937 23.761 20.3033 23.456 20.1722C23.151 20.0412 22.823 19.9723 22.491 19.9694C22.1591 19.9665 21.8299 20.0297 21.5226 20.1554C21.2154 20.2811 20.9362 20.4668 20.7015 20.7015C20.4668 20.9362 20.2811 21.2154 20.1554 21.5226C20.0297 21.8299 19.9665 22.1591 19.9694 22.491C19.9723 22.823 20.0412 23.151 20.1722 23.456C20.3033 23.761 20.4937 24.0369 20.7325 24.2675L26.465 30L20.7325 35.7325C20.4937 35.9631 20.3033 36.239 20.1722 36.544C20.0412 36.849 19.9723 37.177 19.9694 37.509C19.9665 37.8409 20.0297 38.1701 20.1554 38.4774C20.2811 38.7846 20.4668 39.0638 20.7015 39.2985C20.9362 39.5332 21.2154 39.7189 21.5226 39.8446C21.8299 39.9703 22.1591 40.0335 22.491 40.0306C22.823 40.0277 23.151 39.9588 23.456 39.8278C23.761 39.6967 24.0369 39.5063 24.2675 39.2675L30 33.535L35.7325 39.2675C36.204 39.7229 36.8355 39.9749 37.491 39.9692C38.1465 39.9635 38.7735 39.7006 39.237 39.237C39.7006 38.7735 39.9635 38.1465 39.9692 37.491C39.9749 36.8355 39.7229 36.204 39.2675 35.7325L33.535 30L39.2675 24.2675Z"
      fill="#ED6262"
    />
  </svg>
</template>
<template>
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.8204 8.81793C33.8204 3.94855 29.8719 0 25.0025 0C20.1331 0 16.1846 3.94855 16.1846 8.81793V16.1746H33.8254V8.81793H33.8204Z"
      fill="#A2A7DA"
    />
    <path d="M33.8206 33.8164H16.1797V49.996H33.8206V33.8164Z" fill="#27CDCC" />
    <path
      d="M49.9999 16.1738H33.8203V33.8147H49.9999V16.1738Z"
      fill="#7DE1E0"
    />
    <path
      d="M8.81793 16.1749C3.94855 16.1749 0 20.1235 0 24.9929C0 29.8622 3.94855 33.8108 8.81793 33.8108H16.1746V16.1699H8.81793V16.1749Z"
      fill="#7A81CA"
    />
    <path
      d="M33.8206 16.1738H16.1797V33.8147H33.8206V16.1738Z"
      fill="#555A8D"
    />
  </svg>
</template>
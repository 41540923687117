<template>
  <Navbar />
  <div
    class="
      bg-hero-mobile
      md:bg-hero-tablet
      xl:bg-hero-desktop
      bg-no-repeat bg-right-top
    "
  >
    <div
      class="
        bg-mobile
        md:bg-tablet
        xl:bg-desktop
        after:bg-bg-hero
        bg-cover bg-center
      "
    >
      <Hero />
      <Question />
      <CallToAction />
    </div>
  </div>
  <Footer />
</template>

<script>
import Navbar from "../components/Navbar.vue";
import Hero from "@/components/Hero.vue";
import Question from "@/components/Question.vue";
import CallToAction from "@/components/CallToAction.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "HomeView",
  components: {
    Navbar,
    Hero,
    Question,
    CallToAction,
    Footer,
  },
};
</script>